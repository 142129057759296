// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-company-about-js": () => import("./../../../src/pages/our-company/about.js" /* webpackChunkName: "component---src-pages-our-company-about-js" */),
  "component---src-pages-our-company-careers-index-js": () => import("./../../../src/pages/our-company/careers/index.js" /* webpackChunkName: "component---src-pages-our-company-careers-index-js" */),
  "component---src-pages-our-company-careers-style-js": () => import("./../../../src/pages/our-company/careers/style.js" /* webpackChunkName: "component---src-pages-our-company-careers-style-js" */),
  "component---src-pages-our-company-contact-js": () => import("./../../../src/pages/our-company/contact.js" /* webpackChunkName: "component---src-pages-our-company-contact-js" */),
  "component---src-pages-our-company-index-js": () => import("./../../../src/pages/our-company/index.js" /* webpackChunkName: "component---src-pages-our-company-index-js" */),
  "component---src-pages-privacy-policy-and-cookies-js": () => import("./../../../src/pages/privacy-policy-and-cookies.js" /* webpackChunkName: "component---src-pages-privacy-policy-and-cookies-js" */),
  "component---src-pages-resources-index-js": () => import("./../../../src/pages/resources/index.js" /* webpackChunkName: "component---src-pages-resources-index-js" */),
  "component---src-pages-resources-project-gallery-js": () => import("./../../../src/pages/resources/project-gallery.js" /* webpackChunkName: "component---src-pages-resources-project-gallery-js" */),
  "component---src-pages-services-architectural-design-js": () => import("./../../../src/pages/services/architectural-design.js" /* webpackChunkName: "component---src-pages-services-architectural-design-js" */),
  "component---src-pages-services-custom-fabrication-js": () => import("./../../../src/pages/services/custom-fabrication.js" /* webpackChunkName: "component---src-pages-services-custom-fabrication-js" */),
  "component---src-pages-services-equipment-js": () => import("./../../../src/pages/services/equipment.js" /* webpackChunkName: "component---src-pages-services-equipment-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-wood-pellets-js": () => import("./../../../src/pages/services/wood-pellets.js" /* webpackChunkName: "component---src-pages-services-wood-pellets-js" */),
  "component---src-pages-solutions-index-js": () => import("./../../../src/pages/solutions/index.js" /* webpackChunkName: "component---src-pages-solutions-index-js" */),
  "component---src-pages-solutions-solutions-by-role-index-js": () => import("./../../../src/pages/solutions/solutions-by-role/index.js" /* webpackChunkName: "component---src-pages-solutions-solutions-by-role-index-js" */),
  "component---src-pages-solutions-solutions-by-sector-index-js": () => import("./../../../src/pages/solutions/solutions-by-sector/index.js" /* webpackChunkName: "component---src-pages-solutions-solutions-by-sector-index-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-all-resources-page-js": () => import("./../../../src/templates/allResourcesPage.js" /* webpackChunkName: "component---src-templates-all-resources-page-js" */),
  "component---src-templates-all-resources-slug-js": () => import("./../../../src/templates/allResourcesSlug.js" /* webpackChunkName: "component---src-templates-all-resources-slug-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/newsPage.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-news-slug-js": () => import("./../../../src/templates/newsSlug.js" /* webpackChunkName: "component---src-templates-news-slug-js" */)
}

